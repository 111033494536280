import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout/layout';
import Container from '../components/container/container';
import Button from '../components/button/button';

import * as styles from './home.module.scss';

export default function HomePage({ data }) {
  const properties = data.allMarkdownRemark.edges;
  return (
    <Layout title="Home" description="TODO: Add home page description">
      <section className={styles.intro}>
        <StaticImage
          src="../images/home-hero-image.jpg"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={styles.intro__background}
        />
        <Container>
          <div className={styles.intro__panel}>
            <h1>Welcome to Timeless Homes</h1>
            <p className="lg">
              Ipseum decorieu lseorei fleosk woiquw decorieu lseorei
              fleorei&nbsp;seowieru dleorueu dleor ileori rei seowieru.
            </p>
            <Button href="/about-us">Learn more</Button>
          </div>
        </Container>
      </section>

      <section className={`${styles.section} ${styles.sectionOdd}`}>
        <Container className={styles.section__content}>
          <h2 className="h1 txt-primary">
            Our commitment to high specification and an attention to detail is
            rarely found in new homes today
          </h2>
          <p className="range-right">
            <Link to="/properties" className="cta">
              View developments
            </Link>
          </p>
        </Container>
        <StaticImage
          src="../images/home-commitment-image.jpg"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={`${styles.section__image} ${styles.commitmentImage}`}
        />
      </section>

      {properties.map(({ node: property }, index) => (
        <section
          className={`${styles.section} ${
            index % 2 === 0 ? styles.sectionEven : styles.sectionOdd
          }`}
          key={property.fields.slug}
        >
          <StaticImage
            src="../images/properties/mile-house-lane-home-img.png"
            alt=""
            placeholder="blurred"
            layout="fullWidth"
            className={`${styles.section__image} ${styles.property__image}`}
          />
          <Container className={styles.section__content}>
            <div className={styles.property__content}>
              <h2 className={`txt-primary ${styles.property__name}`}>
                {property.frontmatter.title}
              </h2>
              <p className={styles.property__location}>
                {property.frontmatter.location}
              </p>
              <p className={`txt-muted ${styles.property__description}`}>
                {property.frontmatter.excerpt}
              </p>
              <Link to={`/properties${property.fields.slug}`} className="cta">
                Discover more
              </Link>
            </div>
          </Container>
        </section>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query FeaturedPropertiesQuery {
    allMarkdownRemark(filter: { frontmatter: { featured: { eq: true } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            availableDate(formatString: "MMMM yyyy")
            location
            propertyType
            title
            excerpt
          }
        }
      }
    }
  }
`;
