// extracted by mini-css-extract-plugin
export const intro__panel = "home-module--intro__panel--1Pqre";
export const section = "home-module--section--3Y4Cu";
export const section__content = "home-module--section__content--Iodft";
export const property__name = "home-module--property__name--1EkB9";
export const property__location = "home-module--property__location--13pUF";
export const property__description = "home-module--property__description--Izs-4";
export const commitmentImage = "home-module--commitmentImage--2Vfdd";
export const intro = "home-module--intro--1pl4T";
export const intro__background = "home-module--intro__background--12vJ6";
export const section__image = "home-module--section__image--1O7_O";
export const sectionOdd = "home-module--section--odd--16i06";
export const property__image = "home-module--property__image--1jer_";
export const sectionEven = "home-module--section--even--2ZctS";
export const property__content = "home-module--property__content--3mZVK";